// import Vue from 'vue'
// import VueRouter from 'vue-router'
// Vue.use(VueRouter)
// const Home = () => import("../views/Home.vue")
const details = () => import("../views/details/details.vue")
const logins = () => import("../views/login/logins.vue")
const independent = () => import("../views/login/loginsTwo.vue")
const independentReport = () => import("../views/login/loginsTree.vue")
const activity = () => import("../views/myactivity/activity.vue")
const attend = () => import("../views/attendclass/attend.vue")
const classroom = () => import("../views/attendclass/classroom.vue")
const pdfdemo = () => import("../views/attendclass/pdfdemo.vue")
const taskh = () => import("../views/task/taskh.vue")
const sampleReels = () => import("../views/myworks/sampleReels.vue")
const classExample = () => import("../views/classExample/classExample")
const Helpmanual = () => import("../views/Helpmanual/Helpmanual")
const assistant = () => import("../views/assistant/assistant")
const painter = () => import("../views/assistant/painter")
const Viewer = () => import("../views/attendclass/Viewer")
const showreel = () => import("../views/task/showreel.vue")
const curriculum = () => import("../views/task/curriculum.vue")
const studyCurriculum = () => import("../views/task/studyCurriculum.vue")
const wordsEvaluation = () => import("../views/task/wordsEvaluation.vue")
const studywordsEvaluation = () => import("../views/task/studywordsEvaluation.vue")
const collect = () => import("../views/task/collect.vue")
const aitest = () => import("../views/task/aitest.vue")
const aireport = () => import("../views/task/aireport.vue")
const report = () => import("../views/report/report.vue")
const stuReport =()=>import("../views/report/report_stu2.vue")
const AfterReport = () => import("../views/report/report_after.vue")
const NewReport =()=>import("../views/newReport/newReport.vue")
const OldReport =()=>import("../views/report/newreport.vue")
const frontBackaAppraisal =()=>import("../views/newReport/frontBackaAppraisal.vue")
const frontBackaAppraisal_tea =()=>import("../views/newReport/frontBackaAppraisal_tea.vue")
const ReportZhuShouStu =()=>import("../views/report/report_stu_zhushou.vue")
const NewReportZhuShouStu =()=>import("../views/report/report_stu_zhushou_new.vue")
const PersonalReports =()=>import("../views/report/report_stu_zhushou_refactor.vue")
const NewReportZhuShouStuMove =()=>import("../views/report/report_stu_zhushou_new_move.vue")
const NewReportZhuShouStuLogin =()=>import("../views/report/report_stu_zhushou_new_login.vue")
const NewReportZhuShouStuList =()=>import("../views/report/report_stu_zhushou_new_list.vue")
const ReportTest =()=>import("../views/report/report_test.vue")




const profile = () => import("../views/user/profile.vue")
const profileStu = () => import("../views/user/profileStu.vue")
// const studyProject = () => import("../views/studyProject/Home")
const news =()=>import("../views/news/news.vue")

export default [{
        path: '/',
        redirect: '/logins'
    },
    {
        path: '/logins',
        name: 'logins',
        meta: {
            title: '登录',
            keepAlive: false
        },
        component: logins,
    },
    {
        path: '/independent',
        name: 'independent',
        meta: {
            title: '登录1',
            keepAlive: false
        },
        component: independent,
    },
    {
        path: '/independentReport',
        name: 'independentReport',
        meta: {
            title: '登录2',
            keepAlive: false
        },
        component: independentReport,
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            isBack: false,
            title: '首页',
            keepAlive: false
        },
        component: resolve => require(['@/views/Home'], resolve) // 路由懒加载,
    },
    {
        path: '/studyHome',
        name: 'studyHome',
        meta: {
            isBack: false,
            title: '首页',
            keepAlive: false
        },
        component: resolve => require(['@/views/studyProject/Home.vue'], resolve) // 路由懒加载,
    },
    {
        path: '/aloneHome',
        name: 'aloneHome',
        meta: {
            isBack: false,
            title: '首页',
            keepAlive: false
        },
        component: resolve => require(['@/views/studyProject/aloneHome.vue'], resolve) // 路由懒加载,
    },
    {
        path: '/activity',
        name: 'activity',
        meta: {
            title: '我的活动',
            keepAlive: false
        },
        component: activity,
    }, {
        path: '/attend',
        name: 'attend',
        meta: {
            title: '上课',
            keepAlive: false
        },
        component: attend,
    },
    {
        path: '/classroom',
        name: 'classroom',
        meta: {
            title: '上课',
            keepAlive: false
        },
        component: classroom,
    },
    {
        path: '/pdfdemo',
        name: 'pdfdemo',
        meta: {
            title: '我的课堂',
            keepAlive: false
        },
        component: pdfdemo,
    },
    {
        path: '/classExample',
        name: 'classExample',
        meta: {
            title: '入门课例',
            keepAlive: false
        },
        component: classExample,
    },
    {
        path: '/assistant',
        name: 'assistant',
        meta: {
            title: '工具',
            keepAlive: false
        },
        component: assistant,
    },

    {
        path: '/painter',
        name: 'painter',
        meta: {
            title: '画板',
            keepAlive: false
        },
        component: painter,
    },
    {
        path: '/Helpmanual',
        name: 'Helpmanual',
        meta: {
            title: '帮助中心',
            keepAlive: false
        },
        component: Helpmanual,
    },
    {
        path: '/taskh',
        name: 'taskh',
        meta: {
            title: '作业',
            keepAlive: false
        },
        component: taskh,
    }, {
        path: '/details',
        name: 'details',
        meta: {
            title: '个人',
            keepAlive: false
        },
        component: details,
    },
    {
        path: '/sampleReels',
        name: 'sampleReels',
        meta: {
            title: '作品集',
            keepAlive: false
        },
        component: sampleReels,
    },
    {
        path: '/Viewer',
        name: 'Viewer',
        meta: {
            title: 'Viewer',
            keepAlive: false
        },
        component: Viewer,
    },
    {
        path: '/showreel',
        name: 'showreel',
        meta: {
            title: '作品集',
            keepAlive: true,
        },
        component: showreel,
    },
    {
        path: '/curriculum',
        name: 'curriculum',
        meta: {
            title: '作品集',
            keepAlive: true,
        },
        component: curriculum,
    },
    {
        path: '/studyCurriculum',
        name: 'studyCurriculum',
        meta: {
            title: '作品集',
            keepAlive: false,
        },
        component: studyCurriculum,


    },
    // {
    //     path: '/studyCurriculum',
    //     name: 'studyCurriculum',
    //     meta: {
    //         title: '作品集',
    //         keepAlive: true,
    //     },
    //     component: studyCurriculum,


    // },
    {
        path: '/wordsEvaluation',
        name: 'wordsEvaluation',
        meta: {
            title: '作品',
            keepAlive: false,
        },
        component: wordsEvaluation,
    },
    {
        path: '/studywordsEvaluation',
        name: 'studywordsEvaluation',
        meta: {
            title: '作品',
            keepAlive: false,
        },
        component: studywordsEvaluation,
    },



    {
        path: '/profile',
        name: 'profile',
        meta: {
            title: '个人中心',
            keepAlive: true,
        },
        component: profile,
    },
    {
        path: '/profileStu',
        name: 'profileStu',
        meta: {
            title: '个人中心',
            keepAlive: true,
        },
        component: profileStu,
    },
    {
        path: '/collect',
        name: 'collect',
        meta: {
            title: '作品集列表',
            keepAlive: true
        },
        component: collect
    },
    {
        path: '/aitest',
        name: 'aitest',
        meta: {
            title: 'AI测评',
            keepAlive: true
        },
        component: aitest
    },
    {
        path: '/aireport',
        name: 'aireport',
        meta: {
            title: 'AI测评',
            keepAlive: true
        },
        component: aireport
    },
    {
        path: '/report',
        name: 'report',
        meta: {
            title: '测评报告',
            keepAlive: true
        },
        component: report
    },
    {
        path: '/newreport',
        name: 'newreport',
        meta: {
            // title: '测评报告2.0',
            title: '我的报告',
            keepAlive: false
        },
        component: NewReport
    },
    {
        path: '/oldreport',
        name: 'oldreport',
        meta: {
            title: '测评报告2.0',
            keepAlive: false
        },
        component: OldReport
    },
    {
        path: '/frontBackaAppraisal',
        name: 'frontBackaAppraisal',
        meta: {
            title: '前后测评报告2.3',
            keepAlive: false
        },
        component: frontBackaAppraisal
    },
    {
        path: '/frontBackaAppraisal_tea',
        name: 'frontBackaAppraisal_tea',
        meta: {
            title: '教师前后测评报告2.3',
            keepAlive: false
        },
        component: frontBackaAppraisal_tea
    },
    {
        path: '/stuReport',
        name: 'stuReport',
        meta: {
            title: '测评报告',
            keepAlive: true
        },
        component: stuReport
    },
    {
        path: '/reportAfter',
        name: 'reportAfter',
        meta: {
            title: '测评报告',
            keepAlive: true
        },
        component: AfterReport
    },
    {
        path:"/news",
        name:'news',
        meta:{
            title:'新闻',
            keepAlive:false
        },
        component:news
    },
    {
        path:'/ReportZhuShouStu',
        name:'ReportZhuShouStu',
        meta:{
            title:'报告助手',
            keepAlive:false
        },
        component:ReportZhuShouStu
    },
    {
        path:'/ReportTest',
        name:'ReportTest',
        meta:{
            title:'报告助手',
            keepAlive:false
        },
        component:ReportTest
    },
    {
        path:'/NewReportZhuShouStu',
        name:'NewReportZhuShouStu',
        meta:{
            title:'报告助手',
            keepAlive:false
        },
        component:NewReportZhuShouStu
    },
    {
        path:'/PersonalReports',
        name:'PersonalReports',
        meta:{
            title:'报告助手',
            keepAlive:false
        },
        component:PersonalReports
    },
    {
        path:'/NewReportZhuShouStuMove',
        name:'NewReportZhuShouStuMove',
        meta:{
            title:'个人报告',
            keepAlive:false
        },
        component:NewReportZhuShouStuMove
    },
    {
        path:'/NewReportZhuShouStuLogin',
        name:'NewReportZhuShouStuLogin',
        meta:{
            title:'测评登陆',
            keepAlive:false
        },
        component:NewReportZhuShouStuLogin
    },
    {
        path:'/NewReportZhuShouStuList',
        name:'NewReportZhuShouStuList',
        meta:{
            title:'测评列表',
            keepAlive:false
        },
        component:NewReportZhuShouStuList
    },
]