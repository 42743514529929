import {
    get,
    getDoc,
    put,
    patch,
    post,
    fetch
} from "../ApiDecorator"; // 倒入 api
const Screen = {
    //基础数据
    basicsStatistics(data) {
        return get("/learn/screen/statistics/queryData", data);
    },
    //班级数
    getClassCountNotEmptyTeachers(data) {
        return get("/learn/screen/statistics/getClassCountNotEmptyTeachers", data);
    },
    //学生待提升最多的字
    queryWork2Word(data) {
        return get("/learn/screen/statistics/queryWork2Word", data);
    },
    //查询教师信息
    queryTeacherInfo(data) {
        return get("/learn/screen/statistics/queryTeacherInfo", data);
    },
    //最近7次上课统计
    queryLastSevenClass(data) {
        return get("/learn/screen/statistics/queryLastSevenClass", data);
    },
    //学生互动参与情况
    queryStudentJoinData(data) {
        return get("/learn/screen/statistics/queryStudentJoinData", data);
    },
    // 查询作品动态列表
    queryWorkData(data) {
        return get("/learn/screen/statistics/queryWorkData", data);
    },
    // 查询教师培训数据
    queryTrainInfo(data) {
        return get("/learn/screen/statistics/queryTrainInfo", data);
    },

    //区域
    //查询子数据
    queryChildrenData(data) {
        return get("/learn/screen/statistics/queryChildrenData", data);
    },
    //查询班级统计
    queryClassCount(data) {
        return get("/learn/screen/statistics/queryClassCount", data);
    },
    //查询组织信息
    queryOrgInfo(data) {
        return get("/learn/screen/statistics/queryOrgInfo", data);
    },
    //根据组织ID查询教学数据
    queryTeachDataByOrgId(data) {
        return get("/learn/screen/statistics/queryTeachDataByOrgId", data);
    },
    //学校
    // 查询教师作品待提升的字
    queryTeacherPromoteWord(data) {
        return get("/learn/screen/statistics/queryTeacherPromoteWord", data);
    },
    //查询平台应用数据
    queryStatisticsApply(data) {
        return get("/learn/screen/statistics/queryStatisticsApply", data);
    },
    //教师作品提交情况
    queryTeacherSubData(data) {
        return get("/learn/screen/statistics/queryTeacherSubData", data);
    },
    //根据组织ID查询班级排名
    queryClassRankByOrgId(data) {
        return get("/learn/screen/statistics/queryClassRankByOrgId", data);
    },
    //查询教师培训参与情况
    queryTeacherTrainData(data) {
        return get("/learn/screen/statistics/queryTeacherTrainData", data);
    },
    //查询课堂互动教学统计
    queryTeachStatistics(data) {
        return get("/learn/screen/statistics/queryTeachStatistics", data);
    },

    // 当天数据统计
    queryNowDayData(data) {
        return get("/learn/screen/statistics/queryNowDayData", data);
    },
    // 请求教师
    queryTeacherClassSelect(data) {
        return get("/learn/screen/statistics/queryTeacherClassSelect", data);
    },
    // table数据(测评报告)
    queryTableData(data) {
        return get('/learn/screen/statistics/querySubOrgList', data)
    },
    // 班级报告
    queryClassReport(data) {
        return get('/learn/screen/statistics/queryClassReport', data)
    },
    // 个人报告
    queryPersonReport(data) {
        return get('/learn/screen/statistics/queryUserReport', data)
    },
    // 获取学年学期列表
    queryTermList() {
        return get('/learn/screen/statistics/queryTermList')
    },
    //教师上课排行
    queryTeacher_class(data) {
        return get('/learn/screen/statistics/teacher-cumulative-number-classes', data)
    },
    // ------ 报告2.0新增接口 ------

    // 整体报告-注册人数
    queryJoinRate(data) {
        return get('/learn/screen/statistics/queryJoinRate', data)
    },
    // 整体报告-请求一二级维度code
    getwdCode() {
        return get('/learn/screen/statistics/queryExaminationCommentSet')
    },
    // 整体报告-请求评语(包含整体和各年级)
    getwdComment(data) {
        return post('/learn/screen/statistics/queryWorstCommentByHeadCode', data)
    },
    // 整体报告-优秀作品名单
    getExcellentUsers(data) {
        return get('/learn//screen/statistics/queryExcellentUsers', data)
    },
    // 整体报告-优秀作品
    getExcellentWork(data) {
        return get('/learn/screen/statistics/queryExcellentWork', data)
    },
    // 整体报告-分维度分析
    getAnalyseChart(data) {
        return get('/learn/screen/statistics/queryAnalyseChart', data)
    },
    // 个人报告
    getPeronWriteData(data) {
        return get('/learn/screen/statistics/queryPeronWriteData', data)
    },
    // 年级报告-请求评语
    getwdCommentByClass(data) {
        return post('/learn/screen/statistics/queryCommentByHeadCode', data)
    },
    // 整体报告-平台上课总数
    getLessonCourseInfo(data) {
        return get('/learn/screen/statistics/queryLessonCourseInfo', data)
    },
    // 查询是否有新版报告
    // 
    hasNewReport(data) {
        return get('/learn/screen/statistics/checkSchoolHasNewReport', data)
    },
    // 查询报告助手(个人)
    getUserCommentReport(data) {
        return get('/learn/screen/statistics/queryUserCommentReport', data)
    },
    // 查询报告助手(整体)
    getSchoolCommentReport(data) {
        return post('/learn/screen/statistics/querySchoolCommentReport', data)
    },
    // 查询年级列表
    getGradeList(data) {
        return get('/learn/screen/statistics/queryReportGradeList', data)
    },
    //查询班级列表
    getClassList(data) {
        return get('/learn/screen/statistics/queryReportClassList', data)
    },
    //生成学生个人识写能力测评报告
    getUserpdf(data) {
        return get('/learn/literacy-pdf-reports/generate-user-pdf', data)
    },
    //检查下载文件
    getDownloadpdf(data) {
        return get('/learn/literacy-pdf-reports/check-download', data)
    },

    //本班上课总数按天统计最近30天
    getAttendClassNum(data) {
        return get('/learn/screen/statistics/cumulative-number-classes', data)
    },
    //查询学生List
    getUsersList(data) {
        return get('/learn/screen/statistics/getUsersList', data)
    },
    //汉字书写水平（学生个人）接口
    getCharacterWritingLevel(data) {
        return get('/learn/screen/statistics/characterWritingLevel', data)
    },
    //汉字素养水平（学生个人）接口
    getCharacterAttainmentLevel(data) {
        return get('/learn/screen/statistics/characterAttainmentLevel', data)
    },
    //学生应用api(班级)
    //生本学期自学课程情况
    getselfStudySituation(data) {
        return get('/learn/studentApplyClass/selfStudySituation', data)
    },
    //学生本学期APP学习参与率
    getstudyRate(data) {
        return get('/learn/studentApplyClass/studyRate', data)
    },
    //观看最多的课程Top10（人次数不去重）
    getTop10(data) {
        return get('/learn/studentApplyClass/mostWatchedCoursesTop10', data)
    },
    //学生应用时间分布
    getstudentTimeDistribution(data) {
        return get('/learn/studentApplyClass/studentTimeDistribution', data)
    },
    //整体数据
    getaggregateData(data) {
        return get('/learn/studentApplyClass/aggregateData', data)
    },
    //学生app应用排名
    getstudentAPPLearnRank(data) {
        return get('/learn/studentApplyClass/studentAPPLearnRank', data)
    },
    //学生上传作品动态 分页
    getworkDynamics(data) {
        return get('/learn/studentApplyClass/workDynamics', data)
    },
    //书吉语贺新春活动
    getactivityParticipation(data) {
        return get('/learn/studentApplyClass/activityParticipation', data)
    },
    //书吉语贺新春活动作品图分页
    getactivityParticipationImg(data) {
        return get('/learn/studentApplyClass/activityParticipationImg', data)
    },
    // 导出书吉语贺新春活动参与统计Excel
    getexportNewYearActivities(data) {
        return getDoc('/learn/studentApplyClass/exportNewYearActivities', data)
    },
    //导出学生app应用排名Excel
    getexportStudentAPPLearnRank(data) {
        return getDoc('/learn/studentApplyClass/exportStudentAPPLearnRank', data)
    },
    //学生应用api(学校)
    //生本学期自学课程情况
    getselfStudySituation_sch(data) {
        return get('/learn/screenStudentApply/selfStudySituation', data)
    },
    //学生本学期APP学习参与率
    getstudyRate_sch(data) {
        return get('/learn/screenStudentApply/studyRate', data)
    },
    //观看最多的课程Top10（人次数不去重）
    getTop10_sch(data) {
        return get('/learn/screenStudentApply/mostWatchedCoursesTop10', data)
    },
    //整体数据
    getaggregateData_sch(data) {
        return get('/learn/screenStudentApply/aggregateData', data)
    },
    //学生app应用排名 TOP 50
    getstudentAPPLearnRank_sch(data) {
        return get('/learn/screenStudentApply/studentAPPLearnRank', data)
    },
    //学生上传作品动态 分页
    getworkDynamics_sch(data) {
        return get('/learn/screenStudentApply/workDynamics', data)
    },
    //书吉语贺新春活动
    getactivityParticipation_sch(data) {
        return get('/learn/screenStudentApply/activityParticipation', data)
    },
    //书吉语贺新春活动作品图分页
    getactivityParticipationImg_sch(data) {
        return get('/learn/screenStudentApply/activityParticipationImg', data)
    },
    //APP学习各班级应用统计
    getappLearnClassStuNum(data) {
        return get('/learn/screenStudentApply/appLearnClassStuNum', data)
    },
    // 导出书吉语贺新春活动参与统计Excel
    getexportNewYearActivities_sch(data) {
        return getDoc('/learn/screenStudentApply/exportNewYearActivities', data)
    },
    //APP学习各班级应用统计Excel
    getexportAppLearnClassStuNum(data) {
        return getDoc('/learn/screenStudentApply/exportAppLearnClassStuNum', data)
    },
    //学生app应用排名Excel
    getexportStudentAPPLearnRank_sch(data) {
        return getDoc('/learn/screenStudentApply/exportStudentAPPLearnRank', data)
    },
    //教师应用api(学校)
    //本校应用整体分析
    getoverallAnalysis(data) {
        return get('/learn/screenTeachApply/overallAnalysis', data)
    },
    //本校上课类型分布
    gettakeClassTypeDistribution(data) {
        return get('/learn/screenTeachApply/takeClassTypeDistribution', data)
    },
    //教师应用环比
    getteachApplyCountRatio(data) {
        return get('/learn/screenTeachApply/teachApplyCountRatio', data)
    },
    //本校上课时间分布
    getschoolTimeDistribution(data) {
        return get('/learn/screenTeachApply/schoolTimeDistribution', data)
    },
    //本校上课分布 (按类型&年级分类)
    gettakeClassTypeDistributionGroupGrade(data) {
        return get('/learn/screenTeachApply/takeClassTypeDistributionGroupGrade', data)
    },
    //各类课程应用详情
    getdetailsCourseApply(data) {
        return get('/learn/screenTeachApply/detailsCourseApply', data)
    },
    //教师应用详情
    getteacherApplyDetails(data) {
        return get('/learn/screenTeachApply/teacherApplyDetails', data)
    },
    //各年级课程应用情况Excel导出
    getgradeCourseApplyExcel(data) {
        return getDoc('/learn/screenTeachApply/gradeCourseApplyExcel', data)
    },
    //教师应用详情Excel导出
    getteacherApplyDetailsExcel(data) {
        return getDoc('/learn/screenTeachApply/teacherApplyDetailsExcel', data)
    },
    //全国-省-市-区大屏
    //查询地区
    getarea(data) {
        return get('/learn/area', data)
    },
    getById(data) {
        return get('/learn/area/getById', data)
    },
    //查询学校List
    getSchoolList(data) {
        return get('/learn/screenEvalStat/getSchoolList', data)
    },
    // 每月应用统计
    getapplyStatByMonth(data) {
        return get('/learn/screenEvalStat/applyStatByMonth', data)
    },
    //各年级应用统计
    getapplyStatByGrade(data) {
        return get('/learn/screenEvalStat/applyStatByGrade', data)
    },
    //应用最多的教师Top10
    getapplyTeaTop10(data) {
        return get('/learn/screenEvalStat/applyTeaTop10', data)
    },
    //查询 测评成绩TOP10学校
    getEvalScoreTOP10(data) {
        return get('/learn/screenEvalStat/getEvalScoreTOP10', data)
    },
    //查询 各学校应用详情
    getApplyDetail(data) {
        return get('/learn/screenEvalStat/getApplyDetail', data)
    },
    //参与人数统计(全国)
    getcountParticipants(data) {
        return get('/learn/screenEvalStat/countParticipants', data)
    },
    //各地区不同课型应用情况
    getapplyByRegion(data) {
        return get('/learn/screenEvalStat/applyByRegion', data)
    },
    //各地区应用情况（全国）
    getapplyByNationwide(data) {
        return get('/learn/screenEvalStat/applyByNationwide', data)
    },
    //各地区测评情况（全国）
    getEval(data) {
        return get('/learn/screenEvalStat/getEval', data)
    },
    //各时段不同课型应用情况
    getCourseHourLogByAction(data) {
        return get('/learn/screenEvalStat/getCourseHourLogByAction', data)
    },
    //各地区或学校应用详情Excel
    getApplyDetailExcel(data) {
        return getDoc('/learn/screenEvalStat/getApplyDetailExcel', data)
    },
    //获取区域和学校测评成绩TOP10统计表数据Excel
    getEvalScoreTOP10Excel(data) {
        return getDoc('/learn/screenEvalStat/getEvalScoreTOP10Excel', data)
    },
    //应用最多的教师Top10Excel
    getapplyTeaTop10Excel(data) {
        return getDoc('/learn/screenEvalStat/applyTeaTop10Excel', data)
    },
    //全国导出数据
    getexcelByNationwide(data) {
        return get('/learn/screenEvalStat/excelByNationwide', data)
    },
    //全国每月应用统计导出
    getexcelByNationwideByMonthWeek(data) {
        return get('/learn/screenEvalStat/excelByNationwideByMonthWeek', data)
    },
    //全国各省市应用情况导出
    getapplyByNationwideExcel(data) {
        return getDoc('/learn/screenEvalStat/applyByNationwideExcel', data)
    },
    //全国各省市测评情况导出
    getEvalExcel(data) {
        return getDoc('/learn/screenEvalStat/getEvalExcel', data)
    },
    //全国各省市不同课型应用情况导出
    getApplyDetailExcel(data) {
        return getDoc('/learn/screenEvalStat/getApplyDetailExcel', data)
    },
    //区县各学校应用情况
    getschoolApplySituation(data) {
        return get('/learn/screenEvalStat/schoolApplySituation', data)
    },
    //区县各学校应用情况导出
    getschoolApplySituationExcel(data) {
        // return getDoc('/learn/screenEvalStat/schoolApplySituationExcel', data)
        return get('/learn/screenEvalStat/schoolApplySituationExcel', data)
    },
    //学校大屏首页整体数据
    getoverallData(data) {
        return get('/learn/schoolScreenHome/overallData', data)
    },
    //学校大屏各年级应用统计
    getapplyStatByGradeBySchool(data) {
        return get('/learn/screenEvalStat/applyStatByGradeBySchool', data)
    },
    //本校测评等级分布
    getschoolEvalLevel(data) {
        return get('/learn/schoolScreenHome/schoolEvalLevel', data)
    },
    //本校测评等级分布
    getOrgInfo(data) {
        return get('/learn//screen/statistics/getOrgInfo', data)
    },
    //助手类型
    getIsNewDIm(data) {
        return get('/learn/beforeAfterEvalReport/isNewDIm', data)
    },
     //学生个人报告版本
     getReportV(data) {
        return get('/learn/eval-report/ind-report-version', data)
    }
}
export default Screen;