import Home from "./Home";
import Login from "./login";
import Myclass from "./Myclass";
import Myactivity from "./myactivity";
import Example from './classExample'
import Live from './live'
import Profile from './profile'
import DataScreen from './dataScreen'
import Manage from './excellentWords'
import CopyBook from './copybook'
import Activity from './activity'
import Report from './report'
import Upload from './upload'

const Api = {
    Home,
    Login,
    Myclass,
    Myactivity,
    Example,
    Live,
    Profile,
    DataScreen,
    Manage,
    CopyBook,
    Activity,
    Report,
    Upload
};

// 导出
export default Api;