import {
    get,
    put,
    patch,
    post,
    fetch,
    delByBody,
    get2
} from "../ApiDecorator"; // 倒入 api

const Upload = {
    // 获取记录列表
    history(data) {
        return get("/learn/examUploadRecord/history", data)
    },
    // 保存总记录
    saveRecord(data) {
        return post("/learn/examUploadRecord/saveRecord", data)
    },
    // 保存作品图片
    saveImg(data) {
        return post("/learn/examUploadRecord/saveImg", data)
    },
    // 作品列表
    getWorkPage(data) {
        return get("/learn/examUploadRecord/getWorkPage", data)
    },
    // 删除作品
    delWork(data) {
        return delByBody("/learn/examUploadRecord/delete-work", data)
    },
    // 获取班级
    getClassList(data) {
        return get("/learn/class-api/open/selectClassListByUid", data)
    },
    // 获取试卷列表
    getTestList(data) {
        return get("/learn/copy-book/exam-list", data)
    },
    // 获取oss配置
    getQueryToken() {
        return get("/learn/aliFile/getStsToken")
    },
    // 查看图片(生成临时访问链接)
    getTempUrl(data) {
        return get("/learn/file/getTempUrl", data)
    },
    // 导出
    downloadWordList(data) {
        return get2("/learn/examUploadRecord/downloadWordList", data)
    },
    // 生成报告
    createReport(data) {
        return put("/learn/examUploadRecord/generateReports", data)
    },
    // 生成报告-查询剩余人数
    getRemainUserCount(data) {
        return get("/learn/examUploadRecord/getRemainUserCount", data)
    },
    // 根据学校id获取年级id列表
    getGradeIdListBySchoolId(data) {
        return get("/learn/class-info/getGradeIdListBySchoolId", data)
    },
    // 根据学校id年级id获取班级id列表
    getClassListBySchoolId(data) {
        return get("/learn/class-info/getClassListBySchoolId", data)
    },
    // 下载图片
    getTempUrlDownloadFileName(data){
        return get("/learn/file/getTempUrlDownloadFileName",data)
    }
}
export default Upload