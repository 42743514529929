import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import App from './App.vue'
import routes from './router.js'
if (window.location.protocol !== 'https:' && !window.location.href.includes('localhost')) {
    window.location.replace(`https://${window.location.host}${window.location.pathname}`);
}
const router = new VueRouter({
    mode: 'history',
    // mode: 'hash',
    routes
})
import 'amfe-flexible'
import 'amfe-flexible/index'
import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI)
import {
    message
} from './element/resetMessage.js';
Vue.prototype.$message = message;
// import './element/element-variables.scss'
import dayjs from "dayjs"
Vue.prototype.dayjs = dayjs; //全局使用dayjs
import {
    graphicRatio
} from './utils/graphicRatio.js'
Vue.prototype.graphicRatio = graphicRatio;
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)
import vueconciseslider from 'vue-concise-slider'
Vue.use(vueconciseslider)
import moment from "moment"
Vue.prototype.$moment = moment;
import './assets/icon/iconfont'
Vue.config.productionTip = false
import Api from "@/http/index";
Vue.prototype.$api = Api;
Vue.use(Api);
window.Api = Api;
import store from "./vuex/store";
//全局组件
import Subscribe from './Common_components/index'
Vue.use(Subscribe)
import VueStar from 'vue-star'
Vue.component('VueStar', VueStar);
import * as echarts from 'echarts';
import 'echarts-gl';
Vue.prototype.$echarts = echarts;
import 'echarts-wordcloud'

import vueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(vueSeamlessScroll)
//公共方法
import AccessPortrait from './Common_components/js/AccessPortrait.js'
Vue.prototype.$AccessPortrait = AccessPortrait;

// 引入字体库
import '@/assets/fonts/font.css'

//全局守卫
// 设置白名单
const whiteList = ['studyCurriculum', 'studywordsEvaluation'];
const assistant = ['syncProjection']
router.beforeEach((to, from, next) => {
    // 助手跳转
    if (assistant.indexOf(to.name) !== -1) {
        if (to.query.source === 'assistant') {
            if (to.query.token) {
                sessionStorage.token = to.query.token;
                $cookies.set('token', to.query.token);
                next();
                return; // 确保在设置 token 后直接返回，避免继续执行
            }
        } else {
            if ($cookies.get('token')) {
                next();
                return;
            }
        }
        next('/logins');
    }

    if (whiteList.indexOf(to.name) !== -1) {
        if (to.query.token) {
            sessionStorage.token = to.query.token;
            $cookies.set('token', to.query.token);
            next();
        } else if (from.name == 'aloneHome' || from.name == 'studyHome' || from.name == 'studyCurriculum' || from.name == 'studywordsEvaluation') {
            // || from.name == 'NewReportZhuShouStuMove'
            next()
        } else {
            next('/logins');
        }
    } else {
        if (to.meta.title == "个人报告" || to.meta.title == "测评登陆" || to.meta.title == "测评列表") {
            document.title = '首师优字'
        } else {
            document.title = to.meta.title + '-首师优字'
        }
        if (to.meta.title == '首页') {
            sessionStorage.iconIndex = 0;
        } else if (to.meta.title == '我的活动') {
            sessionStorage.iconIndex = 2;
        } else if (to.meta.title == '我教的课') {
            sessionStorage.iconIndex = 0;
        } else if (to.meta.title == '我的直播') {
            sessionStorage.iconIndex = 3;
        } else if (to.meta.title == '作品集') {
            sessionStorage.iconIndex = 4;
        } else {
            sessionStorage.iconIndex = null;
        }
        next();
    }

})
// import VueMasonryPlugin from 'vue-masonry';
// Vue.use(VueMasonryPlugin)
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')