const Upload =()=>import("../views/upload/upload.vue");
const UploadList =()=>import("../views/upload/upload-list.vue");


export default [
    {
        path:'/upload',
        name:"upload",
        meta: {
            title: '批量测评',
            keepAlive: false
        },
        component:Upload
    },
    {
        path:'/upload-list',
        name:"upload-list",
        meta: {
            title: '批量测评',
            keepAlive: false
        },
        component:UploadList
    }
]