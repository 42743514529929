import {
    get,
    put,
    patch,
    post,
    fetch
} from "../ApiDecorator"; // 倒入 api

const CopyBook = {
    getGrade() {
        return get("/learn/model/queryUserGradeList")
    },
    getClassList() {
        return get('/learn/model/queryUserClass')
    },
    getClassStu(data) {
        // return post('/learn/model/queryClassUsers', data)
        return get("/learn/users/getStuListByClassId",data)
    },
    getCopyBookList(data) {
        return get('/learn/model/queryModelPage', data)
    },
    // createCopyBook(data) {
    //     return post('/learn/model/buildModel', data)
    // },
    copyBookDetail(data) {
        return get('/learn/model/queryModelById', data)
    },
    saveLiving(data) {
        return post('/learn/model/saveLivingLesson', data)
    },



    // 查询年级列表
    getGradeList(data){
        return get("/learn/class-api/selectGradeIdList",data)
    },
    // 根据年级查询单元列表
    getCellList(data){
        return get("/learn/copy-book/order-list",data)
    },
    // 根据单元查询课节列表
    getPeriodList(data){
        return get("/learn/copy-book/period-list",data)
    },
    // 生成字帖
    createCopyBook(data) {
        return post('/learn/copy-book/genCopyBook', data)
    },
    // 硬笔体系课查询级别
    getSysLessonListByGradeId(data){
        return get("/learn/sys-resource/getSysLessonListByGradeId",data)
    },
    // 硬笔体系课根据等级查询课节
    queryLesson(data){
        return get("/learn/teach/queryLesson",data)
    },
    // 测评卷获取试卷列表
    getTestList(data){
        return get("/learn/copy-book/exam-list",data)
    },
    // 获取生成字帖记录
    copyBookLogPage(data){
        return get("/learn/copy-book/copyBookLogPage",data)
    },
    // 根据学校id获取年级列表
    getGradeIdListBySchoolId(data){
        return get("/learn/class-info/getGradeIdListBySchoolId",data)
    },
    // 根据学校id、班级id获取绑定学生年级列表
    getClassListBySchoolId(data){
        return get("/learn/class-info/getClassListBySchoolId",data)
    },
    // 获取全年级学生列表
    getStuCountByGradeId(data){
        return get("/learn/users/getStuCountByGradeId",data)
    }
}
export default CopyBook